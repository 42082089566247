@import '../../../styles/index';

.footer {
    &__wrapper {
        width: 100%;
        height: $footer-height;
        box-shadow: 0 1px 6px 3px $silver;
        background: $black;
    }

    &__content {
        font-size: 12px;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;;

        @include tablet {
            flex-direction: column;
        }

        &-help {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            &-link {
                margin-left: 2px;
                color: $sage;
                text-decoration: none;
                font-weight: 700;
            }
        }

        &-copy {
            margin-top: 8px;
        }
    }
}