@mixin x-small-mobile {
    @media (max-width: $xxx-small) {
        @content;
    }
}

@mixin small-mobile {
    @media (max-width: $xx-small) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $small) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $medium) {
        @content;
    }
}

@mixin small-desktop {
    @media (max-width: $large) {
        @content;
    }
}

@mixin large-desktop {
    @media (max-width: $x-large) {
        @content;
    }
}

@mixin x-large-desktop {
    @media (min-width: $x-large + 1px) {
        @content;
    }
}