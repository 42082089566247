@import '../../../styles/index';

.header {
    width: 100%;
    height: $header-height;
    position: fixed;
    display: flex;
    flex-direction: row;
    z-index: 100;
}
