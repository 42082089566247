@import '../../../../styles/index';

.desktop-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;
    box-shadow: 0 1px 6px -1px $silver;
    padding: 0 48px;

    @include tablet {
        display: none;
    }

    &__title {
        &.animate {
            animation: translate-title .2s linear;

            @keyframes translate-title {
                0% {
                   width: 100%;
                }
                50% {
                    width: 50%;
                }
    
                100% {
                    width: 20%;
                }
            }
        }
    }

    &__links {
        font-size: 16px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-left: 24px;

        @include tablet {
            margin-left: 24px;

        }

        &.animate {
            animation: grow-text .35s linear;

            @keyframes grow-text {
                0% {
                   transform: scale(0);
                }
                99% {
                    transform: scale(0);
                }
                100% {
                    transform: scale(1);
                }
            }
        }

        @include tablet {
            font-size: 14px;
        }

        &-link {
            margin-right: 24px;
            color: $silver;
            text-decoration: none;
            white-space: nowrap;

            &.is-external {
                text-decoration: none;  
                margin-right: 24px;
                color: $silver;
                text-decoration: none;
            }

            &.active {
                color: $sage;
            }

            &:hover {
                border-bottom: 1px;
                color: $sage;
            }
        }
    }
}