/* COLORS */
$white: #ffffff;
$black: #000000;
$sage: #8ABD91;
$silver: #808080;

/* Breakpoints */
$x-small: 720px;
$xx-small: 480px;
$xxx-small: 370px;
$small: 768px;
$medium: 1024px;
$large: 1440px;
$x-large: 1915px;

/* Constants */
$header-height: 60px;
$footer-height: 90px;