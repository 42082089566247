@import '../../../../styles/index';

.mobile-header {
    width: 100%;
    display: flex  !important;
    align-items: center;
    justify-content: center;
    background-color: $white;
    box-shadow: 0 1px 6px -1px $silver;

    &__wrapper {
        display: none !important;

        @include tablet {
            display: flex  !important;
        }
    }

    &__top {
       position: relative;
       
        &-icon {
            color: $silver;
            position: absolute;
            top: -12px;
            right: 12px;
            height: 28px !important;
            width: 28px !important;
        }
    }

    &__links {
        &-row {
            width: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            display: flex;
            height: 42px;
            padding: 0 16px 16px;
            text-decoration: none;

            &.is-external {
                color: $silver;
                text-decoration: none;
                font-size: 18px;
                width: 80%;
            }
    
            &.active {
                color: $sage;
            }

            &-link {
                color: $silver;
                text-decoration: none;
                font-size: 18px;
                width: 80%;

                &.active {
                    color: $sage;
                }
    
                &:hover {
                    border-bottom: 1px;
                    color: $sage;
                }
            }

            &-icon {
                width: 20%;
                display: flex;
                color: $silver;

                &.active {
                    color: $sage;
                }
            }

        }

        &-icon {
            width: 100%;
            margin: 18px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-logo {
                background: url("https://becoming-braga-static.s3.amazonaws.com/becoming-braga-icon.png");
                background-repeat: no-repeat; 
                background-size: contain;
                background-position: center;
                height: 64px;
                width: 64px;
                margin-top: 24px;
            }
        }
        
        &-divider {
            margin-bottom: 8px !important;
        }
    }

    &__menu {
        position: absolute;
        left: 24px;
    }
}
