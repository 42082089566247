@import '../styles/index';

body {
    margin: 0px;
    font-family: Roboto;
}

.info-page {
    padding-top: $header-height;
    min-height: calc(100vh - ($header-height + $footer-height));
}

.content-wrapper {
    padding: 64px 24px;
    width: 100%;
    box-sizing: border-box;

    @include mobile {
        padding: 48px 24px;
    }
}

.inline-link {
    text-decoration: none;
    margin: 0 4px;
    color: $sage;
}

.content-section-title {
    font-size: 36px;
    margin-bottom: 48px;
    color: $sage;
    width: 100%;
    text-align: center;

    @include tablet {
        font-size: 24px;
        margin-bottom: 24px;
    }
}