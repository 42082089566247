@import '../../../../styles/index';

.header-title {
    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        background: url("https://becoming-braga-static.s3.amazonaws.com/becoming-braga-icon.png");
        background-repeat: no-repeat; 
        background-size: contain;
        background-position: center;
        margin-right: 8px;
        height: 36px;
        width: 36px;

        @include small-desktop {
            height: 24px;
            width: 24px;
            margin-right: 4px;
        }

        @include tablet {
            height: 24px;
            width: 24px;
            margin-right: 4px;
        }
    }

    &__text {
        font-size: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @include small-desktop {
            font-size: 16px;
        }

        @include tablet {
            font-size: 16px;
        }

        &-becoming {
            color: $silver;
            margin-right: 4px;

            @include small-desktop {
                margin-right: 2px;
            }
        }

        &-braga {
            color: $sage;
        }
    }
}